import React from "react";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Contact: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Bharat Expressions | Get in Touch</title>
        <meta
          name="description"
          content="Reach out to Bharat Expressions for inquiries about our fusion Indian ethnic wear. Connect with us via WhatsApp or Instagram."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-5xl font-sacramento text-brand-purple text-center mb-8">
          Contact Us
        </h1>

        <div className="bg-white shadow-md rounded-lg p-8 max-w-4xl mx-auto border-4 border-brand-purple">
          <h2 className="text-3xl font-semibold mb-6 text-brand-red">
            Get in Touch
          </h2>
          <p className="mb-6">
            We'd love to hear from you! Here's how you can reach us:
          </p>

          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl mb-4 text-brand-blue">WhatsApp</h3>
              <a
                href="https://wa.me/917506198555"
                className="text-brand-green hover:text-brand-blue transition text-lg block mb-4"
              >
                +91 75061 98555
              </a>
              <LazyLoadImage
                src="/media-opt/assets/qr-codes/BE_WA_QR-v3.png"
                alt="WhatsApp QR Code"
                effect="blur"
                className="w-40 h-40"
              />
            </div>
            <div>
              <h3 className="text-2xl mb-4 text-brand-pink">Instagram</h3>
              <a
                href="https://www.instagram.com/bharatexpressions"
                className="text-brand-orange hover:text-brand-pink transition text-lg block mb-4"
              >
                @bharatexpressions
              </a>
              <LazyLoadImage
                src="/media-opt/assets/qr-codes/BE_insta_QR.png"
                alt="Instagram QR Code"
                effect="blur"
                className="w-40 h-40"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
