import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PrintFabric {
  name: string;
  image: string;
  type: "print" | "fabric";
}

const printFabrics: PrintFabric[] = [
  { name: "Ajarakh", image: "ajarakh.png", type: "print" },
  { name: "Kalamkari", image: "kalamkari.png", type: "print" },
  { name: "Bandhej", image: "bandhej.png", type: "print" },
  { name: "Patola", image: "patola.png", type: "print" },
  { name: "Batik", image: "batik.jpg", type: "print" },
  { name: "Dabu", image: "dabu.png", type: "print" },
  { name: "Mashru Silk", image: "mashru.png", type: "fabric" },
  { name: "Cotton", image: "cotton.jpg", type: "fabric" },
  { name: "Modal", image: "modal.jpg", type: "fabric" },
  { name: "Rayon", image: "rayon.jpg", type: "fabric" },
  { name: "Kutch Cotton", image: "kutch-kala-cotton.jpg", type: "fabric" },
];

const PrintFabricItem: React.FC<PrintFabric> = ({ name, image }) => (
  <div className="bg-white p-2 rounded-lg shadow-md">
    <div className="relative pb-[100%]">
      <div className="absolute inset-0">
        <LazyLoadImage
          src={`/media-opt/assets/prints-fabrics/${image}`}
          alt={name}
          effect="blur"
          className="w-full h-full object-cover rounded-lg"
          wrapperClassName="w-full h-full"
        />
      </div>
    </div>
    <p className="text-center font-semibold mt-2">{name}</p>
  </div>
);

interface PrintFabricGridProps {
  type?: "print" | "fabric";
}

const PrintFabricGrid: React.FC<PrintFabricGridProps> = ({ type }) => {
  const itemsToDisplay = type
    ? printFabrics.filter((item) => item.type === type)
    : printFabrics;

  const lgGridClassName = "lg:grid-cols-" + itemsToDisplay.length;

  return (
    <div
      className={`grid grid-cols-2 sm:grid-cols-3 ${lgGridClassName} lg:grid-cols-6 gap-4 mb-8`}
    >
      {itemsToDisplay.map((item) => (
        <PrintFabricItem key={item.name} {...item} />
      ))}
    </div>
  );
};

export default PrintFabricGrid;
