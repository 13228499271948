import React from "react";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const OurStory: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Our Story - Bharat Expressions | Journey of Indian Fashion Fusion</title>
        <meta name="description" content="Learn about Bharat Expressions' journey in revolutionizing Indian ethnic wear. Our story of blending tradition with modern fashion since 2023." />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <section className="bg-white shadow-md rounded-lg p-8 mb-8 border-4 border-brand-purple">
          <div className="md:flex items-top">
            <div className="md:w-1/4 mb-4 md:mb-0">
              <LazyLoadImage
                src="/media-opt/images/founder-v1.jpg"
                alt="Founder of Bharat Expressions"
                effect="blur"
                className="rounded-lg w-full md:max-w-1/3"
              />
            </div>
            <div className="md:w-3/4 md:pl-8">
              <div className="bg-brand-orange shadow-md rounded-lg p-8 mb-8 border-t-8 border-b-8 border-pattern">
                <h2 className="text-3xl font-sacramento text-brand-purple mb-4">
                  Our Story
                </h2>
                <p>
                  Bharat Expressions is your destination for fusion Indian
                  ethnic wear. We're all about blending the richness of Indian
                  tradition with a modern twist. Born from a love for the
                  vibrant prints of Ajarakh, Kalamkari, and Bandhani, we've
                  grown into a brand that offers more than just clothes—we offer
                  stories woven into fabric.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-lg p-8 mb-8 border-t-8 border-b-8 border-pattern">
                <h2 className="text-3xl font-sacramento text-brand-purple mb-4">
                  Our Journey
                </h2>
                <ul className="list-disc list-inside">
                  <li>
                    <strong>Founded:</strong> Launched in February 2023 due to
                    high demand from friends and family for Ajarakh clothes.
                  </li>
                  <li>
                    <strong>Registration:</strong> Officially registered in
                    September 2023 after successful initial trials in styles,
                    prints, and sales.
                  </li>
                  <li>
                    <strong>Vision:</strong> Become a leading name in fusion
                    Indian ethnic wear.
                  </li>
                </ul>
              </div>
              <section className="bg-brand-green shadow-md rounded-lg p-8 border-t-8 border-b-8">
                <h2 className="text-3xl font-sacramento text-brand-purple mb-4">
                  Our Commitment
                </h2>
                <p className="text-lg text-brand-purple">
                  Whether it's the intricate patterns of Patola or the earthy
                  tones of Bagru and Dabu, each piece we create is a celebration
                  of India's heritage, designed for today's woman who values
                  both comfort and flair.
                </p>
              </section>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OurStory;
