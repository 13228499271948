import React, { useState } from "react";
import ProductGrid from "../components/ProductGrid";
import { Helmet } from "react-helmet-async";

const Products: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Our Products - Bharat Expressions | Unique Prints and Fabrics
        </title>
        <meta
          name="description"
          content="Explore our collection of fusion Indian ethnic wear featuring Ajarakh, Kalamkari, Bandhej prints on Mashru Silk, Cotton, and more."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <h2 className="text-4xl font-sacramento text-brand-purple text-center mb-12">
          Our Collections
        </h2>

        <ProductGrid />

        <section className="bg-brand-orange p-8 rounded-lg shadow-md border-pattern border-t-8 border-b-8">
          <h2 className="text-3xl font-bold text-brand-purple mb-6">
            Product Categories
          </h2>
          <ul className="list-disc pl-5 space-y-2 text-lg text-brand-purple">
            <li>Dresses</li>
            <li>One-pieces</li>
            <li>Shirts</li>
            <li>Tops</li>
            <li>Kurtis</li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default Products;
