import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src="/media-opt/assets/logos/BE-Logo-v1.png" alt="Bharat Expressions Logo" className="h-16" />
        </Link>
        <nav className="flex space-x-4">
          <Link to="/" className="text-brand-purple hover:text-brand-red transition">Home</Link>
          <Link to="/our-story" className="text-brand-purple hover:text-brand-red transition">Our Story</Link>
          <Link to="/products" className="text-brand-purple hover:text-brand-red transition">Products</Link>
          <Link to="/contact" className="text-brand-purple hover:text-brand-red transition">Contact</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;