import React, { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";

const collectionId = "one-piece-v-neck";
const dressIds = ["OPVNCBFl", "OPVNRGeAj", "OPVNKRFl"];

interface Dress {
  id: string;
  name: string;
  mainImage: string;
  leftProfile: string;
  rightProfile: string;
  backProfile: string;
  closeUpProfile: string;
}

const dresses: Dress[] = dressIds.map((el) => ({
  id: el,
  name: `One Piece V Neck (${el})`,
  mainImage: `assets/collections/${collectionId}/${el}-FP.webp`,
  leftProfile: `assets/collections/${collectionId}/${el}-LP.webp`,
  rightProfile: `assets/collections/${collectionId}/${el}-RP.webp`,
  backProfile: `assets/collections/${collectionId}/${el}-BP.webp`,
  closeUpProfile: `assets/collections/${collectionId}/${el}-CP.webp`,
}));

const LazyImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (
  props
) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (imgRef.current) {
              imgRef.current.src = props.src || "";
              observer.unobserve(entry.target);
            }
          }
        });
      });

      if (imgRef.current) {
        observer.observe(imgRef.current);
      }

      return () => {
        if (imgRef.current) {
          observer.unobserve(imgRef.current);
        }
      };
    } else {
      // Fallback for browsers that don't support IntersectionObserver
      if (imgRef.current) {
        imgRef.current.src = props.src || "";
      }
    }
  }, [props.src]);

  return (
    <img
      {...props}
      ref={imgRef}
      loading="lazy"
      onLoad={() => setIsLoaded(true)}
      className={`${props.className} ${
        isLoaded ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300`}
    />
  );
};

const ProductGrid: React.FC = () => {
  const [selectedDress, setSelectedDress] = useState<Dress | null>(null);

  return (
    <section className="mb-12">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {dresses.map((dress) => (
          <div
            key={dress.id}
            className="bg-white rounded-lg shadow-md overflow-hidden"
          >
            <LazyImage
              src={dress.mainImage}
              alt={dress.name}
              className="w-full h-64 object-cover object-[center_9%] cursor-pointer"
              onClick={() => setSelectedDress(dress)}
            />
            <div className="p-4">
              <h3 className="text-xl font-semibold text-brand-blue">
                {dress.name}
              </h3>
            </div>
          </div>
        ))}
      </div>

      {selectedDress && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-4xl w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-semibold text-brand-blue">
                {selectedDress.name}
              </h3>
              <X
                className="cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => setSelectedDress(null)}
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              <LazyImage
                src={selectedDress.mainImage}
                alt={`${selectedDress.name} - Main View`}
                className="col-span-2 md:col-span-3 w-full h-64 object-cover object-[center_9%] rounded"
              />
              <LazyImage
                src={selectedDress.leftProfile}
                alt={`${selectedDress.name} - Left Profile`}
                className="w-full h-32 object-cover object-[center_9%] rounded"
              />
              <LazyImage
                src={selectedDress.rightProfile}
                alt={`${selectedDress.name} - Right Profile`}
                className="w-full h-32 object-cover object-[center_9%] rounded"
              />
              <LazyImage
                src={selectedDress.backProfile}
                alt={`${selectedDress.name} - Back Profile`}
                className="w-full h-32 object-cover object-[center_9%] rounded"
              />
              <LazyImage
                src={selectedDress.closeUpProfile}
                alt={`${selectedDress.name} - Close-up Profile`}
                className="w-full h-32 object-cover object-[center_9%] rounded md:col-start-2"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProductGrid;
