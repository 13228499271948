import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-brand-purple text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <p>&copy; {new Date().getFullYear()} Bharat Expressions. All rights reserved.</p>
          </div>
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/bharatexpressions" className="hover:text-brand-orange transition" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
            <a href="https://wa.me/917506198555" className="hover:text-brand-green transition" target="_blank" rel="noopener noreferrer">
              WhatsApp
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;